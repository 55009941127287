import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import adapter from "webrtc-adapter";

function Camera() {
  var video = null;
  video = document.getElementById("video");
  const videoRef = useRef(null);

  const [image, setImage] = useState(null);

  const [cameraDevice, setCameraDevice] = useState({
    video: {
      facingMode: "user",
      width: 1280,
      height: 720,
    },
  });

  const switchCamera = async () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    // Stop the previous media stream tracks
    tracks.forEach((track) => track.stop());

    if (cameraDevice.video.facingMode.exact) {
      setCameraDevice({
        ...cameraDevice,
        video: {
          facingMode: "user",
        },
      });
    } else if (!cameraDevice.video.facingMode.exact) {
      setCameraDevice({
        ...cameraDevice,
        video: {
          facingMode: {
            exact: "environment",
          },
        },
      });
    }
  };

  async function capture() {
    const stream = videoRef.current.srcObject;
    const track = stream.getVideoTracks()[0];

    const imageCapture = new ImageCapture(track);
    const options = { width: 320, height: 320 };

    const blob = await imageCapture.takePhoto(options);

    setImage(URL.createObjectURL(blob));
    const imageUrl = URL.createObjectURL(blob);
    setImage(imageUrl);
  }

  useEffect(() => {
    startCapture();
  }, [cameraDevice, image]);




  
  async function startCapture() {
    const stream = await navigator.mediaDevices.getUserMedia(cameraDevice);

    videoRef.current.srcObject = stream;
    videoRef.current.play();
  }

  const getRandomNumber = () => {
    let min = 1678000;
    let max = 9996789;
    return Math.round(Math.random() * (max - min) + min);
  };
  const downloadImage = () => {
    saveAs(image, getRandomNumber()); // Put your image url here.
  };

  return (
    <div>
      <p>{!cameraDevice.video.facingMode.exact ? "front" : "back"}</p>
      {!image ? (
        <video
          ref={videoRef}
          width={320}
          playsInline
          preload="none"
          id="video"
        ></video>
      ) : (
        <img src={image} alt="capture-imag" width={"100%"} height={"100%"} />
      )}
      <div>
        {image ? (
          <div className="buttton-div">
            <div>
              <button onClick={downloadImage} style={{ marginRight: "30px" }}>
                <img
                  src={"/images/check.png"}
                  alt="check"
                  height={"30px"}
                  width={"30px"}
                />
              </button>
              <p className="margin-class">Ok</p>
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setImage();
                }}
                className="btn btn-primary"
              >
                <img
                  src={"/images/cross.png"}
                  alt="cross"
                  height={"30px"}
                  width={"30px"}
                />
              </button>
              <p>Retake</p>
            </div>
          </div>
        ) : (
          <>
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="btn btn-danger"
              style={{ marginRight: "30px" }}
            >
              <img
                src={"/images/capture.png"}
                alt="capture"
                height={"30px"}
                width={"30px"}
              />
            </button>
            <button onClick={switchCamera}>
              <img
                src={"/images/reverse.png"}
                alt="reverse"
                height={"30px"}
                width={"30px"}
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Camera;
