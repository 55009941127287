import logo from "./logo.svg";
import "./App.css";
import CaptureImage from "./componet/Webcame";
import Camera from "./componet/UsingApi/camera";

function App() {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isiOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  console.log("isAndroid: ", isAndroid);
  return (
    <div className="App">
      <header className="App-header">
        <Camera />
        {/* <CaptureImage /> */}
        {/* {isAndroid && (
          <>
            <Camera />
          </>
        )}
        {isiOS && <Profile />} */}
      </header>
    </div>
  );
}

export default App;
